import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import('../views/Statistics.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/dragons',
    name: 'Dragons',
    component: () => import('../views/Dragons.vue')
  },
  {
    path: '/dragonInfo',
    name: 'DragonInfo',
    component: () => import('../views/DragonInfo.vue')
  },
  {
    path: '/myAccount',
    name: 'MyAccount',
    component: () => import('../views/MyAccount.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
